// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".operations_table_YqoCq .ant-table-footer {\n  padding: 0;\n}\n.operations_table_YqoCq .ant-table-cell {\n  padding: 16px;\n}\n.operations_table_YqoCq .footer_hu9tP {\n  display: grid;\n  grid-template-columns: auto repeat(5, 160px);\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ClientCard/MyProfile/components/Taxes/TaxesTab.m.less"],"names":[],"mappings":"AAAA;EAGQ,UAAA;AADR;AAFA;EAMQ,aAAA;AADR;AALA;EAWI,aAAA;EACA,4CAAA;EAGA,gBAAA;AALJ","sourcesContent":[".operations_table {\n  :global {\n      .ant-table-footer {\n        padding: 0;\n      }\n      .ant-table-cell {\n        padding: 16px;\n      }\n    }\n\n  .footer {\n    display: grid;\n    grid-template-columns: \n      auto \n      repeat(5, 160px);\n    font-weight: 600;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"operations_table": "operations_table_YqoCq",
	"footer": "footer_hu9tP"
};
export default ___CSS_LOADER_EXPORT___;
