import { ITaxOperation, TaxOperationCode, operationCodeTranslate } from "@dltru/dfa-models";
import { ColumnsType, DateTimeCell, getRublesFromPennies, getIntegerPartFromPennies } from "@dltru/dfa-ui";

export const gridConfig: ColumnsType<ITaxOperation> = [
  {
    title: 'Дата операции',
    dataIndex: 'created_at',
    render: DateTimeCell,
  },
  {
    title: 'Операция',
    dataIndex: 'transaction_id',
    render: (value: string) => value ? `ID ${value}` : '-'
  },
  {
    title: 'Тип операции',
    dataIndex: 'operation_code',
    render: (value: TaxOperationCode) => operationCodeTranslate[value]
  },
  {
    title: 'Сумма операции (с учетом комиссии), ₽',
    dataIndex: 'amount',
    width: 160,
    render: (v: number) => getRublesFromPennies(v || 0)
  },
  {
    title: 'Фин. результат операции, ₽',
    dataIndex: 'financial_result',
    width: 160,
    render: (v: number) => getRublesFromPennies(v || 0)
  },
  {
    title: 'Налоговая база, ₽',
    dataIndex: 'income_amount',
    width: 160,
    render: (v: number) => getRublesFromPennies(v || 0)
  },
  {
    title: 'Начисленный НДФЛ, ₽',
    dataIndex: 'tax_amount',
    width: 160,
    render: getIntegerPartFromPennies
  },
  {
    title: 'Удержанный НДФЛ, ₽',
    dataIndex: 'withholding_amount',
    width: 160,
    render: getIntegerPartFromPennies
  },
];