import { Filters, Table, PeriodFilter, Box, getRublesFromPennies, getIntegerPartFromPennies } from "@dltru/dfa-ui"
import { gridConfig } from "./gridConfig"
import { filters } from "./filters"
import moment from "moment"
import styles from './TaxesTab.m.less'
import { useEffect } from "react"
import { FilterField, GetTaxOperationsParams, TaxOperationCode } from "@dltru/dfa-models"
import classNames from "classnames"
import { useDispatch, useSelector } from 'react-redux'
import { taxOperationsSelector } from "@store/tax/operations/selectors"
import { getOperations, getOperationsSummary, taxOperationsSlice } from "@store/tax/operations"
import { authSelector } from "@store/auth/selectors"

export const TaxesTab = () => {
  const reduxDispatch = useDispatch()
  const currentYearStartDate = moment().startOf('year')
  const currentDate = moment()
  const userId = useSelector(authSelector.selectUserUid)
  const isLoading = useSelector(taxOperationsSelector.selectIsLoading)
  const operations = useSelector(taxOperationsSelector.selectItems)
  const paging = useSelector(taxOperationsSelector.selectPaging)
  const summary = useSelector(taxOperationsSelector.selectSummary)

  useEffect(() => {
    const defaultCreatedAt: FilterField<string>[] = [
      `ge(${(currentYearStartDate.format())})`,
      `lt(${(currentDate.format())})`,
    ]
    reduxDispatch(taxOperationsSlice.actions.setParams({ 
      'user_tax.user_id': userId, 
      created_at: defaultCreatedAt,
      operation_type: 'DEBIT'
    }))
    reduxDispatch(getOperations())
    reduxDispatch(getOperationsSummary())
  }, [])

  const onApplyFilters = (filters: Partial<GetTaxOperationsParams>) => {
    let amount: number | FilterField<string>[] | undefined = undefined
    let tax_withholding: number | FilterField<string>[] | undefined = undefined
    let operation_code: TaxOperationCode[] = []
    if (filters.operation_code) {
      operation_code = filters.operation_code
    }
    if (filters.amount) {
      if (typeof filters?.amount === 'number') {
        amount = filters.amount * 100
      }

      if (typeof filters?.amount === 'object') {
        amount = []
        if (filters.amount.gte) {
          amount.push(`ge(${Number(filters.amount.gte) * 100})`)
        }
        if (filters.amount.lte) {
          amount.push(`lt(${Number(filters.amount.lte) * 100})`)
        }
      }
    }
    if (filters.tax_withholding) {
      if (typeof filters?.tax_withholding === 'number') {
        tax_withholding = filters.tax_withholding * 100
      }

      if (typeof filters?.tax_withholding === 'object') {
        tax_withholding = []
        if (filters.tax_withholding.gte) {
          tax_withholding.push(`ge(${Number(filters.tax_withholding.gte) * 100})`)
        }
        if (filters.tax_withholding.lte) {
          tax_withholding.push(`lt(${Number(filters.tax_withholding.lte) * 100})`)
        }
      }
    }
    reduxDispatch(taxOperationsSlice.actions.setParams({ 
        amount,
        operation_code,
        tax_withholding
     }))

    reduxDispatch(getOperations())
    reduxDispatch(getOperationsSummary())
  }

  const onApplyPeriodFilters = (filters?: Partial<GetTaxOperationsParams>) => {
    reduxDispatch(taxOperationsSlice.actions.setParams({ ...filters }))
    reduxDispatch(getOperations())
    reduxDispatch(getOperationsSummary())
  }

  const onPerPageChange = (limit: number) => {
    reduxDispatch(taxOperationsSlice.actions.setParams({ limit }))
    reduxDispatch(getOperations())
  }

  const onNext = () => {
    reduxDispatch(taxOperationsSlice.actions.setParams({ cursor: paging?.cursors?.next }))
    reduxDispatch(getOperations())
  }

  const onPrev = () => {
    reduxDispatch(taxOperationsSlice.actions.setParams({ cursor: paging?.cursors?.prev }))
    reduxDispatch(getOperations())
  }

  return <Box padding={[0, 24, 24, 24]} className="tablebox">
    <Table
      className={styles.operations_table}
      isLoading={isLoading}
      columns={gridConfig}
      dataSource={operations}
      cursors={paging?.cursors}
      onPerPage={onPerPageChange}
      onNext={onNext}
      onPrev={onPrev}
      rowKey='id'
      headerLeft={
        <Filters filters={filters} isQs={false} onApplyFilters={onApplyFilters} />
      }
      headerRight={
        <PeriodFilter
          datePickerDisabled={[true, false]}
          onApplyFilters={onApplyPeriodFilters}
          defaultDatePickerValues={[currentYearStartDate, currentDate]}
        />
      }
      footer={() =>
        <tr className={classNames("ant-table-row", styles.footer)}>
          <td className="ant-table-cell">Итого</td>
          <td className="ant-table-cell">{getRublesFromPennies(summary?.total_amount || 0)}</td>
          <td className="ant-table-cell">{getRublesFromPennies(summary?.total_income_amount || 0)}</td>
          <td className="ant-table-cell" />
          <td className="ant-table-cell" />
          <td className="ant-table-cell">{getIntegerPartFromPennies(summary?.total_tax_withholding)}</td>
        </tr>
      }
    />
  </Box>
}