import {
    InvestorRegistryApplicationStatus,
    InvestorRegistryApplicationStatusTranslateUser,
} from '@dltru/dfa-models'
import cn from 'classnames'
import { FC } from 'react'

import style from './status.m.less'

interface IStatusProps {
    status: InvestorRegistryApplicationStatus
}

export const Status: FC<IStatusProps> = ({ status }) => {
    const label = InvestorRegistryApplicationStatusTranslateUser[status]

    return (
        <div className={cn(style.status, `${style[status]}`)}>
            <span>{label}</span>
        </div>
    )
}
