import { operationCodeTranslate } from '@dltru/dfa-models'
import { Filter, FilterType } from '@dltru/dfa-ui'

const operationOptions = Object.entries(operationCodeTranslate).map(([value, label]) => ({ value, label }))

export const filters: Filter[] = [
    {
      label: 'Тип операции',
      name: 'operation_code',
      filterType: FilterType.SELECT,
      options: operationOptions,
      placeholder: 'Укажите тип операции'
    },
    {
      label: 'Сумма операции, ₽',
      name: 'amount',
      filterType: FilterType.NUMBER_RANGE,
    },
    {
      label: 'Удержанный НДФЛ, ₽',
      name: 'tax_withholding',
      filterType: FilterType.NUMBER_RANGE,
    },
]
