import { InvestorRegistryApplication } from '@dltru/dfa-models'
import { Box, Button, Col, DocumentForvard, Row, Typography } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Status } from '../Status'
import style from './qualificationStatement.m.less'

interface IQualificationStatementProps {
    statement: InvestorRegistryApplication
}

export const QualificationStatement: FC<IQualificationStatementProps> = ({ statement }) => {
    const navigate = useNavigate()

    const onClickHandler = () => {
        navigate(`/qualification-statement/${statement.uuid}`)
    }

    return (
        <Box className={style.statement}>
            <Row gutter={16}>
                <Col span={4}>
                    <div className={style.statementIconContainer}>
                        <DocumentForvard />
                    </div>
                </Col>
                <Col span={14}>
                    <div className={style.statementBody}>
                        <h4 className={style.statementTitle}>
                            Заявление о признании квалифицированным инвестором
                        </h4>
                        <p>от {dayjs.unix(statement.created_at).format('DD-MM-YYYY HH:mm:ss')}</p>
                        <Button type="linkText" onClick={onClickHandler}>
                            Посмотреть
                        </Button>
                    </div>
                </Col>
                <Col span={6}>
                    <Status status={statement.status} />
                </Col>
            </Row>
        </Box>
    )
}
